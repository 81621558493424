<template>
    <div
        class="page"
        v-loading.fullscreen.lock="loading"
        element-loading-spinner="el-icon-loading"
        element-loading-text="加载中，请稍后......"
        element-loading-background="rgba(0, 0, 0, 0.7)"
    >
        <div class="page_header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>推荐有奖</el-breadcrumb-item>
                <el-breadcrumb-item>富文本</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="page_headerSecondary">
            <div></div>
            <div class=""></div>
        </div>
        <!-- 列表 -->
        <div class="page_content">
            <template v-for="el in data">
                <el-form :key="el.id" :model="el">
                    <el-form-item label="标题">
                        <el-input v-model.trim="el.title"></el-input>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch
                            class="sw"
                            v-model="el.status"
                            active-color="#7781f1"
                            inactive-color="#ff4949"
                            active-text="显示"
                            inactive-text="隐藏"
                            :active-value="1"
                            :inactive-value="0"
                        ></el-switch>
                    </el-form-item>
                    <el-form-item label="内容">
                        <Editor class="editor" :txt="el.details" :getTxt="html => (el.details = html)"></Editor>
                    </el-form-item>
                </el-form>
            </template>
        </div>
        <div class="page_fab">
            <VyIcon name="确认" scale="4" @click.native="yes"></VyIcon>
            <VyIcon name="重新加载" scale="4" @click.native="getData"></VyIcon>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import uploadImg from "@/components/customs/uploadImg.vue";
import Editor from "@/components/customs/editor.vue";
import VyIcon from "@/components/customs/VyIcon.vue";

export default {
    components: { uploadImg, Editor, VyIcon },
    data() {
        return {
            data: [],
            loading: false,
        };
    },
    async created() {
        this.getData();
        // let { data } = await axios.get("/api/recommend/detailsshow");
    },

    methods: {
        async getData() {
            this.loading = true;
            let { data } = await axios.get("/api/recommend/detailsshow");
            this.data = data;
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        yes() {
            this.loading = true;
            Promise.all(
                this.data.map(e => {
                    return axios.post("api/recommend/detailsup", { ...e });
                })
            ).then(res => {
                if (res[0].data.code != 200) {
                    this.$message.error(res[0].data.msg);
                } else {
                    this.$message.success(res[0].data.msg);
                }
                this.getData();
            });
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-form {
            background-color: white;
            padding: 20px;
            border-radius: 20px;
            margin-bottom: 20px;
            /deep/.editor {
                border: 1px solid #dcdfe6;
                & > div:nth-of-type(1) {
                    border-bottom: 1px solid #dcdfe6;
                }
            }
        }
    }
    .page_headerSecondary {
        display: flex;
        align-items: center;
        margin-top: 20px;
        & > div {
            flex: 1;
            text-align: right;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
